import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import ServiceIcon from '@mui/icons-material/HomeRepairService';

import CrudDatetimePicker from 'src/components/crud/fields/CrudDatetimePicker';
import CrudText from 'src/components/crud/fields/CrudText';

import api from 'src/services/api';
import getIdLabel from 'src/components/utils/getIdLabel';

import { Task } from 'src/pages/maintenances/Workorder';
import CrudApiSelect from 'src/components/crud/fields/CrudApiSelect';
import DeleteModal from 'src/components/crud/modals/DeleteModal';

import { TaskModalProps } from './TaskModal.d';

const name = {
    singular: 'tarefa',
    plural: 'tarefas',
};

const TaskModal = (props: TaskModalProps) => {
    const { open, onClose, worId, task } = props;

    const { enqueueSnackbar } = useSnackbar();

    /**
     * FORM CONTROL
     */
    const [taskValues, setTaskValues] = useState<Task | null>(null);

    useEffect(() => {
        if (task) {
            setTaskValues({
                ...task,
                start_date: task?.start_date ?? '',
                end_date: task?.end_date ?? '',
                observation: task?.observation ?? '',
                responsible: task?.responsible ?? '',
            });
        }
    }, [open, task]);

    /**
     * SUBMIT
     */
    const submit = async () => {
        if (!task || !taskValues) {
            return;
        }

        return await api.put(`/api/v0/maintenances/workorders/${worId}/tasks/${task.id}/`, {
            start_date: taskValues.start_date !== '' ? taskValues.start_date : null,
            end_date: taskValues.end_date !== '' ? taskValues.end_date : null,
            observation: taskValues.observation,
            is_done: taskValues.is_done,
            responsible: taskValues?.responsible?.id ?? null,
        });
    };

    const { mutate: handleSave, isPending } = useMutation({
        mutationFn: submit,
        onSuccess: () => {
            enqueueSnackbar('Tarefa salva com sucesso!', { variant: 'success' });
            onClose();
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar tarefa!', { variant: 'error' });
        },
    });

    /**
     * SUBMIT REMOVE
     */
    const [removeOpen, setRemoveOpen] = useState(false);
    const [removeEntity, setRemoveEntity] = useState<Task | null>(null);

    const openRemove = () => {
        setRemoveEntity(task);
        setRemoveOpen(true);
    };

    /**
     * RENDER COMPONENT
     */
    if (!task || !taskValues) {
        return <></>;
    }

    const valuesControl = {
        values: taskValues as Task,
        setValues: setTaskValues as Dispatch<SetStateAction<Task>>,
        readOnly: false,
        errors: {},
    };

    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogContent>
                    <Stack direction={'column'} pb={3} height={'100%'} spacing={2}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <ServiceIcon />
                                <Typography variant={'h6'} fontWeight={500} children={task.service.description} />
                            </Stack>

                            <Box>
                                <IconButton onClick={onClose} children={<CloseIcon />} disabled={isPending} />
                            </Box>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
                            <FormControl>
                                <FormControlLabel
                                    label={'Serviço Realizado'}
                                    control={
                                        <Checkbox
                                            checked={taskValues.is_done}
                                            onChange={(e) => {
                                                setTaskValues({
                                                    ...taskValues,
                                                    is_done: e.target.checked,
                                                });
                                            }}
                                        />
                                    }
                                />
                            </FormControl>

                            <Stack direction={'row'} justifyContent={'end'} alignItems={'center'} spacing={1}>
                                <Divider orientation={'vertical'} flexItem={true} />
                                <Typography
                                    variant={'body2'}
                                    fontWeight={500}
                                    color={'textSecondary'}
                                    children={`Tarefa ${getIdLabel(task.id)}`}
                                />
                            </Stack>
                        </Stack>
                    </Stack>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <CrudDatetimePicker
                                {...valuesControl}
                                column={{
                                    accessorKey: 'start_date',
                                    header: 'Início',
                                    field: {
                                        type: 'datetime',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CrudDatetimePicker
                                {...valuesControl}
                                column={{
                                    accessorKey: 'end_date',
                                    header: 'Fim',
                                    field: {
                                        type: 'datetime',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CrudApiSelect
                                {...valuesControl}
                                column={{
                                    accessorKey: 'responsible.name',
                                    header: 'Responsável',
                                    field: {
                                        type: 'search',
                                        name: 'responsible',
                                        endpoint: '/api/v0/registry/persons/',
                                        labelKey: (e) => (e ? e.name : ''),
                                        queryParams: {
                                            is_employee: true,
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CrudText
                                {...valuesControl}
                                column={{
                                    accessorKey: 'observation',
                                    header: 'Observação',
                                    field: {
                                        type: 'text',
                                        multiline: true,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{
                        py: 1.5,
                        px: 3,
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderTop: '1px solid #E0E0E0',
                        bgcolor: '#F9F9F9',
                    }}
                >
                    <Button
                        variant={'outlined'}
                        color={'error'}
                        children={'Remover'}
                        onClick={() => openRemove()}
                        disabled={isPending}
                        startIcon={isPending ? <CircularProgress size={16} /> : <DeleteIcon />}
                    />

                    <Stack direction={'row'} spacing={1}>
                        <Button variant={'outlined'} children={'Cancelar'} onClick={onClose} disabled={isPending} />
                        <Button
                            variant={'contained'}
                            children={'Salvar'}
                            onClick={() => handleSave()}
                            disabled={isPending}
                            startIcon={isPending ? <CircularProgress size={16} /> : <SaveIcon />}
                        />
                    </Stack>
                </DialogActions>
            </Dialog>

            {/** REMOVE CONFIRM */}
            <DeleteModal
                // STRUCTURE
                name={name}
                endpoint={`/api/v0/maintenances/workorders/${worId}/tasks/`}
                // MODAL CONTROL
                modalEntity={removeEntity}
                setOpen={setRemoveOpen}
                open={removeOpen}
                // ACTIONS
                refresh={() => onClose()}
            />
        </>
    );
};

export default TaskModal;
