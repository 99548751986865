import React from 'react';
import ReactDOM from 'react-dom/client';
import { SnackbarProvider } from 'notistack';

// core styles
import './styles/styles.css';

// vendor styles
import 'react-datetime/css/react-datetime.css';
import 'react-js-cron/dist/styles.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <SnackbarProvider>
        <App />
    </SnackbarProvider>
);
