/**
 * STORE COMPANIES INFORMATION ON LOCALSTORAGE
 */

import api from '../../api';
import {Visual} from '../company';

/**
 * Identifier for the company data on localstorage
 * @type {string}
 */
export const COMPANY_KEY = '@maintenance-companies-visual';

/**
 * Set company visual in localstorage
 * @param {object} visual - The company visual to be stored in localstorage
 */
export const setCompanyVisualData = (visual: Visual | null) => {
    let companiesData = {};
    const subdomain = window.location.host.split('.')[0];
    const localData = localStorage.getItem(COMPANY_KEY);

    if (localData) {
        companiesData = JSON.parse(localData);
    }

    localStorage.setItem(
        COMPANY_KEY,
        JSON.stringify({
            ...companiesData,
            [subdomain]: visual,
        }),
    );
};

/**
 * Get company visual data from localstorage
 * @returns {object | null} - Returns the company visual data if present in localstorage, null otherwise
 */
export const getCompanyVisualData = async () => {
    let companiesData = null;
    const subdomain = window.location.host.split('.')[0];
    const localData = localStorage.getItem(COMPANY_KEY);

    if (localData) {
        companiesData = JSON.parse(localData);
    }

    if (!companiesData || !companiesData.hasOwnProperty(subdomain)) {
        await fetchCompanyVisualData();
        companiesData = JSON.parse(localStorage.getItem(COMPANY_KEY) ?? '');
    }

    return companiesData?.[subdomain] ?? null;
};


/**
 * Remove company visual data from localstorage
 */
export const removeCompaniesVisualData = () => {
    localStorage.removeItem(COMPANY_KEY);
};

/**
 * Fetch company visual data from the API
 */
export const fetchCompanyVisualData = async () => {
    try {
        const response = await api.get('/api/v0/companies/visual/');
        setCompanyVisualData(response.data);
    } catch (err) {
        setCompanyVisualData(null);
    }
};

