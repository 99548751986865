import { MeasurementUnit } from 'src/pages/catalog/MeasurementUnits';
import { Subgroup } from 'src/pages/catalog/products/Subgroup';

export const CATEGORIES = {
    FUEL: 1,
    TIRE: 2,
    OIL: 3,
};

export const CATEGORY_OPTIONS = {
    [CATEGORIES.FUEL]: 'Combustível',
    [CATEGORIES.TIRE]: 'Pneu',
    [CATEGORIES.OIL]: 'Óleo',
    na: 'Não se aplica',
};

export type Category = 'na' | (typeof CATEGORIES)[keyof typeof CATEGORIES];

export interface Product {
    avg_unit_cost: number;
    category?: Category;
    description: string;
    diameter: number;
    height: number;
    id: number;
    is_active: boolean;
    length: number;
    subgroup: Subgroup;
    thickness: number;
    unit: MeasurementUnit;
    weight: number;
    width: number;
}
