import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import styled from '@mui/material/styles/styled';

import api from '../../../services/api';
import { ApiSelectProps } from '../ApiSelect';

const StyledTextField = styled(TextField, {
    shouldForwardProp: (prop) => prop !== 'hasAction',
})<{ hasAction: number }>(({ hasAction }) => ({
    ...(hasAction
        ? {
              '& .MuiOutlinedInput-root': {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
              },
          }
        : {}),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    textTransform: 'lowercase',
}));

const ApiSelect = (props: ApiSelectProps) => {
    const {
        endpoint,
        error,
        getOptionLabel,
        helperText,
        label,
        placeholder,
        multiple,
        onChange,
        readOnly,
        required,
        value,
        queryParams,
        inputProps = {},
        inputAction,
    } = props;

    const [searchText, setSearchText] = useState('');
    const [options, setOptions] = useState([]);
    const typingTimeoutRef = useRef<any>(null);

    const [loading, setLoading] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    // TODO: migrate to usequery
    const fetchOptions = () => {
        if (!endpoint) {
            return;
        }

        api.get(endpoint, {
            params: {
                ...queryParams,
                search: searchText,
            },
        }).then((res) => {
            setOptions(res.data.results);
            setLoading(false);
        });
    };

    useEffect(() => {
        if (!firstLoad && value && getOptionLabel(value) === searchText) {
            setLoading(false);
            return;
        }

        setLoading(true);
        setFirstLoad(false);

        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(fetchOptions, 750);

        return () => {
            clearTimeout(typingTimeoutRef.current);
        };
    }, [searchText]);

    useEffect(() => {
        if (!readOnly) {
            fetchOptions();
        }
    }, [endpoint, queryParams]);

    const handleInputChange = (event: SyntheticEvent, newSearchText: string) => {
        setSearchText(newSearchText);
    };

    return (
        <Autocomplete
            multiple={multiple}
            filterOptions={(x) => x}
            openText={'Abrir'}
            closeText={'Fechar'}
            clearText={'Remover'}
            loadingText={'Carregando...'}
            noOptionsText={searchText !== '' ? 'Sem opções para o filtro.' : 'Pesquisar...'}
            loading={loading}
            options={options}
            isOptionEqualToValue={(option: Record<string, any>, val: Record<string, any> | null) =>
                !!val && !!val.id && option.id.toString() === val.id.toString()
            }
            getOptionLabel={(option) => getOptionLabel(option)}
            value={value}
            onInputChange={handleInputChange}
            onChange={(event, val) => onChange(event, val)}
            disabled={readOnly}
            renderInput={(params) => (
                <FormGroup row>
                    <StyledTextField
                        {...params}
                        placeholder={placeholder}
                        label={label}
                        variant={'outlined'}
                        required={required && (multiple ? value?.length === 0 : !value)}
                        error={error}
                        helperText={helperText}
                        hasAction={inputAction ? 1 : 0}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color={'inherit'} size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        {...inputProps}
                        sx={{
                            flex: 10,
                        }}
                    />

                    {inputAction && (
                        <StyledButton
                            variant={'contained'}
                            disableElevation
                            sx={{
                                backgroundColor: '#444',
                            }}
                            onClick={inputAction.onClick}
                        >
                            {inputAction.label}
                        </StyledButton>
                    )}
                </FormGroup>
            )}
        />
    );
};

export default ApiSelect;
