import React from 'react';

import Grid from '@mui/material/Grid';

import DocsIcon from '@mui/icons-material/Description';

import CrudApiSelect from 'src/components/crud/fields/CrudApiSelect';
import CrudDatetimePicker from 'src/components/crud/fields/CrudDatetimePicker';
import CrudSelect from 'src/components/crud/fields/CrudSelect';
import CrudText from 'src/components/crud/fields/CrudText';

import { ASSET_TYPES_LABELS_MAP } from 'src/pages/fleet/Fleet.d';
import ModalSection from 'src/components/ModalSection';

import { TabInfoProps } from './TabInfo.d';
import { CATEGORIES, CATEGORIES_LABELS } from 'src/pages/maintenances/Workorder/Category.d';

const TabInfo = (props: TabInfoProps) => {
    const { valuesControl } = props;

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Grid container spacing={2} my={2}>
                <Grid item xs={4}>
                    <CrudSelect
                        {...valuesControl}
                        column={{
                            accessorKey: 'asset_type',
                            header: 'Tipo de Ativo',
                            field: {
                                type: 'select',
                                options: ASSET_TYPES_LABELS_MAP,
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CrudApiSelect
                        {...valuesControl}
                        column={{
                            accessorKey: 'asset.description',
                            header: 'Ativo',
                            field: {
                                type: 'search',
                                name: 'asset',
                                endpoint: '/api/v0/fleet/assets/',
                                labelKey: (brand) => (brand ? brand.description : ''),
                                queryParams: {
                                    asset_type: valuesControl.values.asset_type,
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <CrudSelect
                        {...valuesControl}
                        column={{
                            accessorKey: 'is_external',
                            header: 'Tipo',
                            field: {
                                type: 'select',
                                options: {
                                    true: 'Externa',
                                    false: 'Interna',
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <CrudApiSelect
                        {...valuesControl}
                        column={{
                            accessorKey: 'enterprise.description',
                            header: 'Empresa',
                            field: {
                                type: 'search',
                                name: 'enterprise',
                                endpoint: '/api/v0/registry/enterprises/',
                                labelKey: (e) => (e ? e.name : ''),
                            },
                        }}
                    />
                </Grid>
            </Grid>

            <ModalSection title={'Agendamento'} icon={DocsIcon} defaultExpanded>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <CrudSelect
                            {...valuesControl}
                            column={{
                                accessorKey: 'category',
                                header: 'Categoria',
                                field: {
                                    type: 'select',
                                    options: CATEGORIES_LABELS,
                                    default: CATEGORIES.PREVENTIVE,
                                },
                            }}
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <CrudDatetimePicker
                            {...valuesControl}
                            column={{
                                accessorKey: 'scheduled_date',
                                header: 'Data Agendamento',
                                field: {
                                    type: 'datetime',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <CrudText
                            {...valuesControl}
                            column={{
                                accessorKey: 'scheduled_hodometer',
                                header: 'Hodômetro Agendamento',
                                field: {
                                    type: 'number',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <CrudText
                            {...valuesControl}
                            column={{
                                accessorKey: 'scheduled_hourmeter',
                                header: 'Horímetro Agendamento',
                                field: {
                                    type: 'number',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </ModalSection>

            <ModalSection title={'Execução'} icon={DocsIcon} defaultExpanded>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <CrudApiSelect
                            {...valuesControl}
                            column={{
                                accessorKey: 'responsible.description',
                                header: 'Responsável',
                                field: {
                                    type: 'search',
                                    name: 'responsible',
                                    endpoint: '/api/v0/registry/persons/',
                                    labelKey: (e) => (e ? e.name : ''),
                                    queryParams: {
                                        is_employee: true,
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <CrudDatetimePicker
                            {...valuesControl}
                            column={{
                                accessorKey: 'start_date',
                                header: 'Data Início',
                                field: {
                                    type: 'datetime',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <CrudDatetimePicker
                            {...valuesControl}
                            column={{
                                accessorKey: 'end_date',
                                header: 'Data Fim',
                                field: {
                                    type: 'datetime',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CrudText
                            {...valuesControl}
                            column={{
                                accessorKey: 'observation',
                                header: 'Observação',
                                field: {
                                    type: 'text',
                                    multiline: true,
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </ModalSection>
        </>
    );
};

export default TabInfo;
