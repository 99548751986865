import React, { useMemo, useState } from 'react';

import dayjs from 'dayjs';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import PlanIcon from '@mui/icons-material/Checklist';
import LooseIcon from '@mui/icons-material/FileDownloadDone';

import { Task } from 'src/pages/maintenances/Workorder';

import AddServiceModal from './AddServiceModal';
import TaskCard from './TaskCard';
import TaskModal from './TaskModal';

import { TabServicesProps, TaskPlan } from './TabServices.d';

const TabServices = (props: TabServicesProps) => {
    const { values, refetch } = props;

    /**
     * ADD SERVICES MODAL
     */
    const [addServicesOpen, setAddServicesOpen] = useState(false);

    /**
     * TASK MODAL
     */
    const [taskOpen, setTaskOpen] = useState(false);
    const [taskEntity, setTaskEntity] = useState<Task | null>(null);

    /**
     * GROUP TASKS BY PLAN
     */
    const plans: TaskPlan[] = useMemo(
        () =>
            Object.values(
                values.tasks.reduce((acc: Record<string, any>, task: Task) => {
                    const id = task?.plan?.id ?? 0;

                    if (!acc[id]) {
                        acc[id] = {
                            ...(id
                                ? task.plan
                                : {
                                      id: 0,
                                      description: 'Avulsos',
                                  }),
                            tasks: [],
                        };
                    }

                    acc[id].tasks.push(task);

                    return acc;
                }, {})
            ).sort((a, b) => (a.id === 0 ? 1 : b.id === 0 ? -1 : a.description.localeCompare(b.description))),
        [values.tasks]
    );

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'end'} my={2}>
                <Button
                    startIcon={<AddIcon />}
                    children={'Add Serviços'}
                    variant={'outlined'}
                    onClick={() => setAddServicesOpen(true)}
                />
            </Stack>

            <Stack spacing={2} pb={3}>
                {plans.map((plan) => (
                    <Stack key={`plan-${plan.id}`} spacing={1}>
                        <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            {plan.id === 0 ? <LooseIcon /> : <PlanIcon />}
                            <Typography variant={'h6'} fontWeight={500} children={plan.description} />
                            <Divider sx={{ flexGrow: 1 }} />
                        </Stack>
                        {plan.tasks.map((task) => (
                            <TaskCard
                                key={`task-${task.id}`}
                                task={task}
                                onClick={(task, is_done) => {
                                    setTaskEntity({
                                        ...task,
                                        is_done: is_done ?? task.is_done,
                                        end_date:
                                            task.end_date ?? (is_done ? dayjs().format('YYYY-MM-DDTHH:mm:ss') : ''),
                                    });
                                    setTaskOpen(true);
                                }}
                            />
                        ))}
                    </Stack>
                ))}
            </Stack>

            {/* ADD SERVICES MODAL */}
            <AddServiceModal
                workorderId={values.id}
                open={addServicesOpen}
                onClose={() => setAddServicesOpen(false)}
                onAdd={(plans) => {
                    setAddServicesOpen(false);
                    refetch();
                }}
                // FILTERS TO THE SEARCH
                workorderPlanIds={plans.map((p) => p.id).filter((p) => p !== 0)}
                assetModelId={values.asset?.asset_model?.id}
            />

            {/* TASK MODAL */}
            <TaskModal
                open={taskOpen}
                onClose={() => {
                    refetch();
                    setTaskOpen(false);
                }}
                worId={values.id}
                task={taskEntity}
            />
        </>
    );
};

export default TabServices;
