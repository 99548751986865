/**
 * STORE USER INFORMATION ON LOCALSTORAGE
 */

import api from '../../api';
import { User } from '../user';

/**
 * Identifier for the user data on localstorage
 * @type {string}
 */
export const USER_KEY = '@maintenance-user';

/**
 * Set user data in localstorage
 * @param {object} user - The user data to be stored in localstorage
 */
export const setUserData = (user: User | null) => {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
};

/**
 * Get user data from localstorage
 * @returns {object | null} - Returns the user data if present in localstorage, null otherwise
 */
export const getUserData = async () => {
    const userData = sessionStorage.getItem(USER_KEY);

    // If no user data is found, call the api
    if (userData == null) {
        return await fetchUserData();
    }

    return JSON.parse(userData);
};

/**
 * Remove user data from localstorage
 */
export const removeUserData = () => {
    localStorage.removeItem(USER_KEY);
};

/**
 * Fetch user data from the API
 */
export const fetchUserData = async () => {
    return await api
        .get('/api/v0/users/my/')
        .then((res) => {
            if (res?.data) {
                setUserData(res.data);
            }
            return res?.data;
        })
        .catch((err) => {
            removeUserData();
        });
};
