import React from 'react';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import DoneIcon from '@mui/icons-material/TaskAlt';
import NotDoneIcon from '@mui/icons-material/RadioButtonUnchecked';

import { TaskCardProps } from './TaskCard.d';

const TaskCard = (props: TaskCardProps) => {
    const { task, onClick } = props;

    /**
     * RENDER COMPONENT
     */
    return (
        <Card variant={'outlined'}>
            <CardActionArea
                onClick={() => {
                    onClick(task);
                }}
            >
                <CardContent>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Stack direction={'row'} alignItems={'center'}>
                            <Tooltip title={task.is_done ? 'Realizado' : 'Marcar como realizado'}>
                                <IconButton
                                    component={'div'}
                                    color={task.is_done ? 'success' : 'default'}
                                    children={task.is_done ? <DoneIcon /> : <NotDoneIcon />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onClick(task, !task.is_done);
                                    }}
                                />
                            </Tooltip>

                            <Typography variant={'body1'} fontWeight={'bold'} children={task.service.description} />
                        </Stack>
                        {task.responsible && (
                            <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                <Divider orientation={'vertical'} flexItem={true} />
                                <Typography
                                    variant={'body2'}
                                    color={'textSecondary'}
                                    children={task.responsible.name}
                                />
                            </Stack>
                        )}
                    </Stack>
                    <Typography
                        variant={'caption'}
                        textAlign={'justify'}
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            WebkitBoxOrient: 'vertical',
                        }}
                        children={task.observation}
                    />
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default TaskCard;
