import { Product } from 'src/pages/catalog/products/Product';
import { Brand } from 'src/pages/registry/Brand';

export const PROPERTY_TYPES = {
    OWN: 'owned',
    THIRD_PARTY: 'third_party',
};

export const PROPERTY_TYPES_LABELS_MAP = {
    [PROPERTY_TYPES.OWN]: 'Próprio',
    [PROPERTY_TYPES.THIRD_PARTY]: 'Terceiros',
};

export type PropertyType = keyof typeof PROPERTY_TYPES;

export const ASSET_TYPES = {
    VEHICLE: 1,
    IMPLEMENT: 2,
    EQUIPMENT: 3,
};

export const ASSET_TYPES_LABELS_MAP = {
    [ASSET_TYPES.VEHICLE]: 'Veículo',
    [ASSET_TYPES.IMPLEMENT]: 'Implemento',
    [ASSET_TYPES.EQUIPMENT]: 'Equipamento',
};

export type AssetType = (typeof ASSET_TYPES)[keyof typeof ASSET_TYPES];

export interface AssetTire {
    id: number;
    tire: Product;
    brand: Brand;
    quantity: number;
    dot: number;
}
